// *** Using `babel-polyfill` fixes **most** of our IE11 issues. If we want to cut back we can individually polyfill here in the future.
import "babel-polyfill";

// import 'core-js/features/array/from';
// import 'core-js/features/array/find';
// import 'core-js/features/array/includes';
// import 'core-js/features/function/name';
// import 'core-js/features/object/assign';
// import 'core-js/features/promise';
// import 'core-js/es7/array';
// import 'core-js/es7/object'
// import 'core-js/es7/function'

// Add your polyfills
// This files runs at the very beginning (even before React and Next.js core)

// Object.assign = assign;
// Function.prototype.name = name;

